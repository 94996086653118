import { css } from '@emotion/react';
import { Text } from '@opendoor/bricks-next';
import { Box, Flex, Image, Link } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';
import { motion } from 'framer-motion';
import { Navigation } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentQuotation, ILpElementQuotation } from 'declarations/contentful';

import Container from './shared/Container';
import { show } from './shared/motion/MotionVariants';
import { getComponentThemeColors, IComponentThemeOptions } from './shared/styles/ComponentTheme';
import { Gutter, OuterMargin, OuterMarginFlex, StackStyle } from './shared/styles/SharedStyles';
import { TitleWithAccent } from './shared/Typography';

const CircularImage = ({ url, altText }: { url: string; altText: string }) => {
  return <Image boxSize="80px" src={url} borderRadius="full" alt={altText} />;
};

const OneUpQuotation = ({ quote }: { quote: ILpElementQuotation }) => {
  return (
    <Container>
      <Box as={motion.div} variants={show} initial="start" animate="end">
        <Box
          backgroundColor="warmgrey200"
          borderRadius="semiRounded"
          w="100%"
          display="flex"
          gap={['48px', '62px']}
          padding={['64px 24px', '64px 24px', '127px 24px', '127px']}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {/* Quotes Box */}
          <Box maxW={[null, null, '950px']}>
            <Text
              tag="h2"
              typography="$headerLarge"
              $smallerThanLG={{
                typography: '$headerSmall',
              }}
              $smallerThanMD={{
                typography: '$subheaderMedium',
              }}
              color="$contentPrimary"
              textAlign="center"
              display="block"
            >
              {'\u201C'}
              {quote.fields.quote}
              {quote.fields.quoteTextAccent && (
                <Text
                  tag="span"
                  typography="$subheaderMedium"
                  $largerThanMD={{
                    typography: '$headerLarge',
                  }}
                  color="$contentTertiary"
                >
                  {' '}
                  {quote.fields.quoteTextAccent}
                  {'\u201D'}
                </Text>
              )}
              {!quote.fields.quoteTextAccent && '\u201D'}
            </Text>
          </Box>

          {/* Quotes Author info Box */}
          <Box
            display="flex"
            flexDirection="column"
            gap="24px"
            alignItems="center"
            justifyContent="center"
          >
            {quote.fields.userImage && (
              <CircularImage
                url={quote.fields.userImage.fields.file.url}
                altText={quote.fields.authorName || ''}
              />
            )}
            <Box textAlign="center">
              {quote.fields.authorName && (
                <Text
                  tag="p"
                  typography="$subheaderSmall"
                  fontWeight="$medium"
                  color="$contentPrimary"
                  display="block"
                >
                  {quote.fields.authorName}
                </Text>
              )}
              {quote.fields.authorInfo && (
                <Text
                  tag="p"
                  typography="$subheaderSmall"
                  fontWeight="$medium"
                  color="$contentTertiary"
                  display="block"
                >
                  {quote.fields.authorInfo}
                </Text>
              )}
              <Link
                color={'blue700'}
                _hover={{ color: 'blue500' }}
                fontSize="16px"
                fontWeight="medium"
                lineHeight="130"
                analyticsName={`cosmos-landing-page-v2-quotation-${quote.fields?.analyticsId}`}
                aria-label={`Go to page ${quote.fields?.ctaDisplayText}`}
                href={quote.fields.ctaDisplayUrl}
              >
                {quote.fields.ctaDisplayText}
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const QuotationCard = ({
  imageUrl,
  componentTheme,
  authorName,
  authorInfo,
  ctaDisplayText,
  ctaDisplayUrl,
  quoteText,
  quoteTextAccent,
  analyticsId,
}: {
  imageUrl?: string;
  componentTheme: IComponentThemeOptions;
  authorName?: string;
  authorInfo?: string;
  ctaDisplayText?: string;
  ctaDisplayUrl?: string;
  quoteText?: string;
  quoteTextAccent?: string;
  analyticsId?: string;
}) => {
  return (
    <Box
      backgroundColor={componentTheme.bg}
      borderRadius="semiRounded"
      w="100%"
      display="flex"
      padding="32px"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
      minH="480px"
      gridColumn="auto / span 1"
    >
      <Box
        padding={[null, '16px', '32px']}
        display="flex"
        alignItems="center"
        flexDirection="column"
        gap={[Gutter]}
      >
        <CircularImage url={imageUrl || ''} altText={authorName || ''} />
        <Box maxW={[null, '300px']} textAlign="center">
          <Text tag="p" typography={'$bodyLarge'} color="$contentPrimary">
            {'\u201C'}
            {quoteText}
            {quoteTextAccent && (
              <>
                {' '}
                {quoteTextAccent}
                {'\u201D'}
              </>
            )}
            {!quoteTextAccent && '\u201D'}
          </Text>
        </Box>
        <Link
          color={componentTheme.linkColor}
          _hover={{ color: componentTheme.linkHoverColor }}
          fontSize="16px"
          fontWeight="medium"
          lineHeight="130"
          analyticsName={`cosmos-landing-page-v2-quotation-${analyticsId}`}
          aria-label={`Go to page ${ctaDisplayText}`}
          href={ctaDisplayUrl}
        >
          {ctaDisplayText}
        </Link>
      </Box>

      <Box textAlign="center">
        {authorName && (
          <Text
            tag="p"
            typography="$bodyLarge"
            fontWeight="$medium"
            color="$contentPrimary"
            display="block"
          >
            {authorName}
          </Text>
        )}
        {authorInfo && (
          <Text
            tag="p"
            typography="$bodyMedium"
            fontWeight="$medium"
            color="$contentTertiary"
            display="block"
          >
            {authorInfo}
          </Text>
        )}
      </Box>
    </Box>
  );
};

const sliderStyles = css(`
.swiper {
  opacity: 0;
  padding: 0 ${OuterMargin};
  transition: opacity 0.3s cubic-bezier(0.55, 0, 0.15, 1);
}

.swiper-slide {
  height: unset;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.55, 0, 0.15, 1);
}

.swiper-slide-visible {
  opacity: 1;
}

.swiper-initialized {
  opacity: 1;
}

.swiper-button-previous,
.swiper-button-next {
  align-items: center;
  background-color: ${novo.colors.warmgrey100};
  border-radius: ${novo.radii.roundedSquare};
  color: ${novo.colors.warmgrey950};
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  transition: transform 0.3s cubic-bezier(0.55, 0, 0.15, 1), opacity 0.3s cubic-bezier(0.55, 0, 0.15, 1) 0.15s;
  width: 40px;
}

.swiper-button-previous:active,
.swiper-button-next:active {
  transform: scale(0.8);
}

.swiper-button-disabled {
  cursor: default;
  opacity: 0.5;
}

.swiper-button-disabled:active {
  transform: none;
}

.swiper-pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  position: static;
}

.swiper-pagination-bullet-active {
  border-radius: 99em;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.15, 1);
  width: 24px;
}
`);

const QuoteCarousel = ({
  id,
  entry,
  componentTheme,
}: {
  id: string;
  entry: ILpComponentQuotation;
  componentTheme: IComponentThemeOptions;
}) => {
  const { quotes } = entry.fields;
  return (
    <Box
      display={[null, null, 'flex']}
      css={sliderStyles}
      ml="auto"
      mr="auto"
      maxW="1440px"
      gap={Gutter}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        w={[null, null, '50%']}
        mb={['48px', null, 0]}
        pl={OuterMarginFlex}
      >
        <QuotationHeader entry={entry} componentTheme={componentTheme} />
        <Flex gap={Gutter}>
          {/* Ignore Bricks linting for pagination because Swiper did not play well with it. */}
          {/* eslint-disable @opendoor/no-html-elements */}
          <button className={`swiper-button-previous swiper-button-previous-${id}`} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M11 14L5 8L11 2"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button className={`swiper-button-next swiper-button-next-${id}`} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5 14L11 8L5 2"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {/* eslint-enable @opendoor/no-html-elements */}
        </Flex>
      </Box>
      <Box w={[null, null, '50%']}>
        <Swiper
          slidesPerView={1.1}
          navigation={{
            prevEl: `.swiper-button-previous-${id}`,
            nextEl: `.swiper-button-next-${id}`,
          }}
          modules={[Navigation]}
          spaceBetween={24}
          watchSlidesProgress={true}
          breakpoints={{
            768: {
              slidesPerView: 1,
            },
            1200: {
              slidesPerView: 2,
            },
          }}
        >
          {quotes?.map((quote, i) => {
            const imageUrl = quote.fields.userImage?.fields.file.url;
            const authorName = quote.fields.authorName;
            const authorInfo = quote.fields.authorInfo;
            const analyticsId = quote.fields.analyticsId;
            const ctaDisplayText = quote.fields.ctaDisplayText;
            const ctaDisplayUrl = quote.fields.ctaDisplayUrl;
            const quoteText = quote.fields.quote;
            const quoteAccentText = quote.fields.quoteTextAccent;

            return (
              <SwiperSlide key={i}>
                <QuotationCard
                  componentTheme={componentTheme}
                  imageUrl={imageUrl}
                  authorName={authorName}
                  authorInfo={authorInfo}
                  analyticsId={analyticsId}
                  ctaDisplayUrl={ctaDisplayUrl}
                  ctaDisplayText={ctaDisplayText}
                  quoteText={quoteText}
                  quoteTextAccent={quoteAccentText}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};

const QuotationHeader = ({
  entry,
}: {
  entry: ILpComponentQuotation;
  componentTheme: IComponentThemeOptions;
}) => {
  const { eyebrow, title, titleAccent } = entry.fields;
  return (
    <Box mb="48px" __css={{ ...StackStyle }}>
      <Text tag="span" typography="$bodyMedium" fontWeight="$medium" color="$contentPrimary">
        {eyebrow}
      </Text>
      {title && <TitleWithAccent title={title} titleAccent={titleAccent} />}
    </Box>
  );
};

const RenderQuotation = (entry: ILpComponentQuotation) => {
  const { type, quotes, backgroundColor } = entry.fields;
  const componentTheme = getComponentThemeColors(backgroundColor || 'Earth 200');
  if (!quotes) return null;
  if (type === '1-up') return <OneUpQuotation quote={quotes[0]} />;

  const gridColumns = type === '4-up' ? 4 : 3;
  return (
    <Box as={motion.div} variants={show} initial="start" animate="end" className="lp-section">
      {type === 'Carousel' ? (
        <QuoteCarousel id={entry.sys.id} entry={entry} componentTheme={componentTheme} />
      ) : (
        <Container>
          <QuotationHeader entry={entry} componentTheme={componentTheme} />
          <Box
            display="grid"
            gridTemplateColumns={[null, null, 'repeat(2, 1fr)', `repeat(${gridColumns}, 1fr)`]}
            gap={[Gutter]}
          >
            {quotes?.map((quote, i) => {
              if (!quote) return null;

              const imageUrl = quote.fields.userImage?.fields.file.url;
              const authorName = quote.fields.authorName;
              const authorInfo = quote.fields.authorInfo;
              const analyticsId = quote.fields.analyticsId;
              const ctaDisplayText = quote.fields.ctaDisplayText;
              const ctaDisplayUrl = quote.fields.ctaDisplayUrl;
              const quoteText = quote.fields.quote;
              const quoteAccentText = quote.fields.quoteTextAccent;

              return (
                <QuotationCard
                  key={i}
                  componentTheme={componentTheme}
                  imageUrl={imageUrl}
                  authorName={authorName}
                  authorInfo={authorInfo}
                  analyticsId={analyticsId}
                  ctaDisplayUrl={ctaDisplayUrl}
                  ctaDisplayText={ctaDisplayText}
                  quoteText={quoteText}
                  quoteTextAccent={quoteAccentText}
                />
              );
            })}
          </Box>
        </Container>
      )}
    </Box>
  );
};

const Quotation: EntryComponent<ILpComponentQuotation> = {
  render: RenderQuotation,
};

export default Quotation;
